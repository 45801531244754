import './styles.css'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { Route ,Link } from 'react-router-dom'

const Cards = (props) => {
    

    const {company, desc, url, liveUrl} = props


    return (
        <motion.div  layout className="cardContainer" >
            <h3 className="cardTitle">{company}</h3>
            <p className="desc">
            {desc}
            </p>

            <div className="links">

              { liveUrl && 
              <div className="mb-2">
                <a href={liveUrl} target="_blank">Live</a>
              </div>
              
              }
            
            {url && 
             <Link to={url}>ReadMore</Link>
            }
            </div>
          

        </motion.div>
    )
}


export default Cards