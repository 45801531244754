import video from './funkoPop.MP4'



const FunCone = () => {



    return (
        <div className="container rubyText">
<video width="400" height="500" controls >
      <source src={video} type="video/mp4"/>
     </video>

     <p>This was a fun project that I developed using React Native, Elasticsearch, and Express.</p>
<p>Elasticsearch is a powerful search engine that allows me to perform full-text queries based on multiple fields, 
    such as the title, description, category, and series.
</p>
<p>The goal is to enable users to search for their favorite toys and check where they can buy them at a reasonable price. I have integrated the eBay API to search for vendors selling a particular toy.</p>
<p>Users will have the ability to search through 2,500 Funko Pop toys and add them to their collections or wishlists.</p>
<p>This app is compatible with both IOS and Android devices.</p>
</div>
    )

}



export default FunCone