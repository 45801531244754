import { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import _ from 'lodash'
import Card from '../Card'
import './styles.css'
import Data from '../../Data'


const Experience = (props) => {



    // const data = [
    //     {company: "RubensteinTech", 
    //      desc: "As a front-end developer, I collaborated with a skilled backend developer to improve the user experience of the cart and checkout pages. The design was provided by the custo...",
    //      type: "workRelated"
    //     },

    //     {company: "Jenner&Block", 
    //      desc: "As a front-end developer, I collaborated with a skilled backend developer to improve the user experience of the cart and checkout pages. The design was provided by the custo...",
    //      type: "workRelated"
    //     },
    //     {company: "Fragomen", 
    //     desc: "As a front-end developer, I collaborated with a skilled backend developer to improve the user experience of the cart and checkout pages. The design was provided by the custo...",
    //     type: "personal"
    //    },
    //    {company: "eMarketer", 
    //    desc: "As a front-end developer, I collaborated with a skilled backend developer to improve the user experience of the cart and checkout pages. The design was provided by the custo...",
    //    type: "personal"
    //     },
    //     {company: "eMarketer", 
    //    desc: "As a front-end developer, I collaborated with a skilled backend developer to improve the user experience of the cart and checkout pages. The design was provided by the custo...",
    //    type: "personal"
    //     },

    // ]


    const [displayBox, setBox] = useState(Data)






    const cards = _.map(displayBox, (x) =>{
        return (
            <Card company={x.company} desc={x.desc} url={x.route} liveUrl={x.liveUrl}/>
        )
    })



    const showAll = () => {

        setBox(Data)

    }


    const showWorkRelated = () => {

        const filtered = _.filter(Data, (x) =>{
            return x.type === "workRelated"
        })

        setBox(filtered)
    }

    const showPersonal = () =>{


        const filtered = _.filter(Data, (x) =>{
            return x.type === "personal"
        })

        setBox(filtered)

    }

    const showMobile = () => {

        const filtered = _.filter(Data, (x) =>{
            return x.mobile
        })

        setBox(filtered)
    }

    


    return(
        <div id="portfolio" className="container mt-5">
            <h2 className="portfolio">Portfolio</h2>


            <div className="ctaContainer">
            <button  className="cta" onClick={showAll}>All</button>
            <button className="cta" onClick={showWorkRelated}>Work Related</button>
            <button  className="cta" onClick={showPersonal}>Personal</button>
            <button  className="cta" onClick={showMobile}>Mobile</button>
            </div>

            <motion.div layout className='cardBox'>
                <AnimatePresence>

                {/* <Card company="eMarketer"/> */}
                {cards}
                </AnimatePresence>

            </motion.div>



        </div>
    )
}



export default Experience