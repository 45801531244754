
import './styles.css'

const Footer = () => {


    return(
        <div className='container text-white mt-5' >

            <div className="">
            <span>&copy; Created By Tarik Mulholland</span>
            </div>
            

        </div>
    )
}


export default Footer 