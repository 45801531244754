import TextSphere from "../TagSphere"
import './styles.css'
import _ from 'lodash'
import { renderSimpleIcon, ICloud, SimpleIcon } from "react-icon-cloud"
import { siJavascript, siGithub, siElasticsearch, siHtml5, siSass, siReact, siJest, siCypress, siMysql, siBootstrap, siK6, siNodedotjs, siDocker, siTypescript, siRedux } from 'simple-icons';
import { Cloud } from "react-icon-cloud";



const renderCustimIcon = (icon) => {

    return renderSimpleIcon({
      icon,
      size: 45,
    })
  }


  const cloudProps = {

    containerProps: {
      style: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: 40
      }
    },
  
    options: {
      clickToFront: 500,
      depth: 1,
      imageScale: 2,
      initial: [0.1, -0.1],
      outlineThickness: 50,
      reverse: true,
      tooltip: "native",
      tooltipDelay: 0,
      wheelZoom: false,
      minSpeed: 0.05,
      noSelect: true,
    }
  }


  
function about(){

    const texts = [
        
        "Javascript",
        'NodeJs',
        "HTML",
        "CSS/SASS",
        "Java",
        "ElasticSearch",
        "React",
        "Jest/Enzyme",
        "Cypress",
        "Mysql",
        "Git",
        "BootStrap",
        "K6",
        'Docker',
        'TypeScript'

    ]

    const staticIcons = [siJavascript, siGithub, siElasticsearch, siHtml5, siSass, siReact, siJest, siCypress, siMysql, siBootstrap, siK6, siNodedotjs, siDocker, siTypescript, siRedux ];


  const cloudIcons = staticIcons.map((i) => renderCustimIcon(i))


    const list = _.map(texts, (text) =>{
        return (
        
                <li>{text}</li>    
            
        )
    })

    

    return(
        <div  id="about" className="container aboutContainer mt-5">
           

        <div className="d-lg-flex mt-5 text">
            <div>

            <h2 className="title">
            A Unique Perspective on Life and Work
            </h2>
                <p>
                As a Software developer, I combine my love for coding with my passion for design to create beautiful and functional websites. My expertise includes HTML, CSS, JavaScript, React and I am always eager to learn new technologies and frameworks.
                </p>

                <p>
                When I'm not coding, you can find me staying active outdoors, whether it's going to the gym, biking or just hanging out with friends. I believe that maintaining a healthy lifestyle is key to staying energized and productive.
                </p>
                <p>
                I'm also an avid gamer and love exploring the world of video games. As a developer, I find the process of creating games fascinating, from designing the characters and environments to programming the game mechanics.
                </p>
            </div>

           
           <div className="d-none d-lg-block cloud">
           <Cloud {...cloudProps}>
      {cloudIcons}
      </Cloud>
            </div>
            <div className="d-lg-none">
            <ul className="list">
                {list}
            </ul>
            </div>
            
            
            </div>
        </div>
    )

}



export default about