import './styles.css'
import { HashLink as Link } from 'react-router-hash-link';




function Nav(){


    return (
        <div className="nav container ">
            
            <div >
                
                <span className="about">
                    <a href='/#about'>About</a>
                </span>
                <span>
                    <Link to="/#portfolio">Portfolio</Link> 
                </span>

                   
            </div>

            <div>
                <span className="about">
                    <a href='https://www.linkedin.com/in/tarikm35' target="_blank">

                    Linkedin
                    </a>
                    
                    </span>
                <span  className="email">
                    <a href="mailto: Tarik.Mulholland@gmail.com">Contact</a>
                    </span>
            </div>
        </div>
    )

}



export default Nav