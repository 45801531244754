
import _ from 'lodash'
import { useEffect } from 'react';
import Nav from './components/Nav';
import Portfolio from './components/portfolio';
import About from './components/about'
import Experience from './components/Experience'
import Footer from './components/Footer';
import Ruben from './components/Ruben';
import Emarketer from './components/emarketer'
import Pokemon from './components/Pokemon'
import FunCone from './components/FunCone';
import Delivery from './components/delivery';
import Onboarding from './components/onboarding';
import Fashion from './components/Fashion';
import { Routes, Route } from "react-router-dom";
import Data from './Data'
//import ReactGA from 'react-ga'
import './App.css'



//ReactGA.initialize('G-MNHPVNZ2H0');

const Home = () => {



  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);
  return (
    <div>

      <div className="d-flex justify-content-center">
      <Portfolio/>
      </div>

      <About/>
      <Experience/>

    </div>
  )
}



function App() {


  // const routes = [

  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  //   {
  //     path : '/portfolio/RubensteinTech',
  //     element : <Ruben/>
  //   },
  // ]

  return (
    <div>

      <Nav/>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/portfolio/RubensteinTech' element={<Ruben/>} />
        <Route path='/portfolio/Emarketer' element={<Emarketer/>} />
        <Route path='/portfolio/Pokemon' element={<Pokemon/>} />
        <Route path='/portfolio/FunCone' element={<FunCone/>} />
        <Route path='/portfolio/Deliver' element={<Delivery/>} />
        <Route  path='portfolio/Onboarding' element={<Onboarding/>}/>
        <Route  path='portfolio/Fashion' element={<Fashion/>}/>

      </Routes>
      <Footer/>


      

    </div>
  );
}

export default App;
