let components = [
    {company: "RubensteinTech", 
    desc: "As a Software engineer, I collaborated with managers and engineers to maintain and enhance features for a application that caters to lawfirms",
    type: "workRelated",
    route: "portfolio/RubensteinTech"
   },

   {company: "Jenner&Block", 
    desc: "A Lawfirm site that is run by RubensteinTech CMS system, where I was heavily involved on creating the frontend from the ground up",
    type: "workRelated",
    route: "portfolio/RubensteinTech",
    liveUrl: "https://www.jenner.com/"
   },
   {company: "Fragomen", 
   desc: "A Lawfirm site that is run by RubensteninTech CMS system, where I played more of a supportive role in adding features",
   type: "workRelated",
   route: "portfolio/RubensteinTech",
   liveUrl: "https://www.fragomen.com/"
  },
  {company: "eMarketer", 
  desc: "As a intern I worked closely with the lead senior Engineer to test and optimize performance of their data service",
  type: "workRelated",
  route: "portfolio/eMarketer"
   },

   {
    company: 'FunCone',
    desc: "A Mobile app to maintain and search for Funko Pops",
    type: 'personal',
    mobile : true,
    route: 'portfolio/FunCone'
   },
   {
    company: 'Delivery',
    desc: "A Mobile app to get food from your favorite restaurants",
    type: 'personal',
    mobile : true,
    route: 'portfolio/Deliver'
   },
   {
    company: 'Fashion',
    desc: "A fashion store app to get the latest trendy clothes",
    type: 'personal',
    mobile : true,
    route: 'portfolio/Fashion'
   },
   {
    company: "Hangman",
    desc: "A classic word game based on different categories",
    type: 'personal',
    liveUrl: 'https://hangman-lemon-psi.vercel.app/'
   },

   {
    company: "BMI Calculator",
    desc: "calculate your body mass index",
    type: "personal",
    liveUrl: "https://bmi-calculator-sigma-ten.vercel.app"
   },
   {
    company: 'OnBoarding',
    desc: "an onboarding sequence for when a user first uses the App",
    type: 'personal',
    mobile : true,
    route: 'portfolio/OnBoarding'
   },
   {
    company: "Audiophile",
    desc: "A ecommerce website to display audio products",
    type: "personal",
    liveUrl: "https://audiophile-jet.vercel.app/"
   },
   {
    company: "Dictionary",
    desc: "What word do you want to look up? Search for a word and know how to pronouce it",
    type: "personal",
    liveUrl: "https://dictionary-six-flax.vercel.app/"
   },
   {company: "Pokemon", 
   desc: "A fun group project where we recreated our favorite game to help us understand the inner mechanics of developing a game",
   type: "personal",
   route: "portfolio/Pokemon"
    },

]

export default components