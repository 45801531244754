import gif from './onboarding.gif'
import pic1 from './pic1.PNG'
import pic2 from './pic2.PNG'
import './styles.css'



const Onboarding = () => {



    return (
        <div>
           <div className="container d-flex justify-content-between flex-wrap">
                <img src={gif}  />

                <img src={pic1}/>

                <img  src={pic2}/>
            </div>
    
        </div>
    )

}


export default Onboarding