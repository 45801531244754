import './styles.css'


const Ruben = () => {


    return (
        <div className="container rubyText mt-5 mb-5">

            <h1>RubensteinTech</h1>


            <h3>Intern</h3>
            <ul>

                <li>
                    Created 80% of test cases for the Automatic Testing Framework which uses Cypress to run through the application to ensure performance output.
                </li>
                <li>
                    Documenting bugs and giving clear and concise steps on how to reproduce for the core application issue.    
                </li>
                <li>
                    Integreted Cypress with CI/CD so the testing suite can be run across multiple projects on a daily basis to ensure reliability and confidence that the projects are in good health.
                </li>
                <li>
                    Enhanced the testing suite by implementing K6 to stress test the application using loading and spiking techniques.
                </li>
            </ul>
        


            <h3>Software Engineer</h3>

            <ul>

                <li>Provided support across multiple projects to help fix bugs and add new changes based on the clients needs.</li>
                <li>Mentored two interns to assist them in maintaining and enhancing the Automatic Testing Framework.</li>
                <li>Conducted code reviews to ensure the quality of the code is being adhere to and helping the interns whenever they were stuck on issues</li>
                <li>
                    Handled transfering hundreds of thousands of sensitive data from the clients legacy database to be digestable data for RubensteinTech CMS to consume and creating spreadsheets of that data for the client to review.
                </li>
                <li>
                Utilized Best Practice templates and creating custom react components to develop fully functional websites such as Jenner&Block, Fragomen, and Dykema
                </li>
                <li>
                    Fixed web content accessibility issues following the WCAG 2.0 guidelines.
                </li>
                <li>
                    Deployed updates to the staging and production servers on a scheduled basis.
                </li>
            </ul>
          

        </div>
    )


}





export default Ruben