 

 import gif from './pokeDemo.gif'
 import { FaGithub } from 'react-icons/fa'

 const Pokemon = () =>{

    return (
        <div className="container rubyText mt-5">

            <img src={gif}></img>
            <ul className="mt-5">
                <li>Using java, I led a team to design a fun roly playing game</li>
                <li>Assigned roles and task as well as utilizing Scrum methodologies to ensure deadlines were met</li>
                <li>Worked on the battle mechanics and multi-threading process for the game</li>
            </ul>



            {/* <a href="https://github.com/Soma586/Pokemon">preview</a> */}

            <a href="https://github.com/Soma586/Pokemon" target="_blank" style={{fontSize: "32px", color: "white"}}>
                    <FaGithub/>
                </a>

        </div>
    )
 }

 export default Pokemon