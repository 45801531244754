import video from './deliver.MP4'
import pic1 from './pic1.PNG'
import pic2 from './pic2.PNG'
import pic3 from './pic3.PNG'
import pic4 from './pic4.PNG'




 export const Card = ({img}) => {

    return (
        <img
        src={img}
        className={"mb-5"}
        style={{height : 400 , width : 200}}
        />
    )
}

const Delivery = () => {
  
    const pics = [
     
        {
            img : pic2
        },
        {
            img : pic3
        },
        {
            img : pic4
        },
        {
            img : pic1
        },
    ]


    const displayCards = pics.map((item) => {

        return <Card img={item.img}/>
    })


    return (
        <div className="container rubyText">
            <video width="400" height="500" controls >
      <source src={video} type="video/mp4"/>
     </video>


     <div className="d-flex justify-content-between flex-wrap">
         {displayCards}

     </div>


     <p>
         This app was developed with react native and redux. <a href={"https://github.com/Soma586/delivery"}>GitHub link</a>
     </p>


        </div>
    )
}


export default Delivery