import './styles.css'
import { FaGithub } from 'react-icons/fa'

function portfolio(){

    
    //550x485
    return (

        <div className="container aceContainer mt-5">


            <div className="box"> 

            <div className="topsymbolContainer"> 
                <p>A</p>
                <p className="topbuffer">♠</p>
            </div>
            <div className="bottomsymbolContainer"> 
                <p className="bottombuffer">A</p>
                <p>♠</p>
            </div>
                <h1 className="buffer cardTitle "> Tarik Mulholland</h1>

                <h2 className= "cardTitle"> Software Engineer</h2>

                <div className="icon">
                <a href="https://github.com/Soma586" target="_blank">
                    <FaGithub/>
                </a>
                </div>
                

                
            </div>

        </div>
    )


}




export default portfolio