
const Emarketer = () => {


    return (
        <div className="container mt-5 mb-5 rubyText">


            <h1>eMarketer</h1>

            <ul>
                <li>Worked closely with the lead senoir Engineer to test and optimize performance of their data service</li>
                <li>Helped added feature on homapre that increased user traffic by 15%</li>
                <li>Detangled and renovate dependencies that boost performance after sudden surge of user traffic</li>
                <li>Collaboreated with the development team using Scrum methodologies</li>
                <li>Experienced Github - Teamcity - Octopus - AWS - Kubernetes deploment process</li>
            </ul>

        </div>
    )
}




export default Emarketer