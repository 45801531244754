import Demo from './demo.mov'
import pic1 from './pic1.PNG'
import pic2 from './pic2.PNG'
import pic3 from './pic3.PNG'
import pic4 from './pic4.PNG'
import { Card } from '../delivery'


const Fashion = () => {


    const pics = [
     
        {
            img : pic1
        },
        {
            img : pic4
        },
        {
            img : pic3
        },
    ]


    const displayCards = pics.map((item) => {

        return <Card img={item.img}/>
    })

    return (
        <div className="container rubyText">

<video width="400" height="500" controls >
      <source src={Demo} type="video/mp4"/>
     </video>


     <div className="d-flex justify-content-between flex-wrap">
         {displayCards}

     </div>

            <p>
                This app was built with react native, expo router and
                redux <a href={"https://github.com/Soma586/Fashion"}>GitHub link</a>
            </p>
        </div>
    )
}


export default Fashion